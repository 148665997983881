<template>
  <div id="nuxt-app" ref="ScrollEle">
    <div id="page-view" :class="$route.name">
      <Nuxt />
    </div>
    <!-- <GtBackToTop target="#__layout">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.3536 14.3536C18.1583 14.5488 17.8417 14.5488 17.6464 14.3536L12 8.70711L6.35355 14.3536C6.15829 14.5488 5.84171 14.5488 5.64645 14.3536C5.45118 14.1583 5.45118 13.8417 5.64645 13.6464L11.6464 7.64645C11.8417 7.45118 12.1583 7.45118 12.3536 7.64645L18.3536 13.6464C18.5488 13.8417 18.5488 14.1583 18.3536 14.3536Z"
          fill="#14141E"
        />
      </svg>
    </GtBackToTop> -->
    <div
      v-show="back2TopVisible && otherHiddenBackTop"
      class="el-backtop gt-backtop"
      style="
        bottom: 60px !important;
        width: 40px !important;
        height: 40px !important;
        background-color: white !important;
        z-index: 99;
      "
      :style="{
        right: isMobile ? '8px !important' : '60px !important'
      }"
      @click="handleScrollBack"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.3536 14.3536C18.1583 14.5488 17.8417 14.5488 17.6464 14.3536L12 8.70711L6.35355 14.3536C6.15829 14.5488 5.84171 14.5488 5.64645 14.3536C5.45118 14.1583 5.45118 13.8417 5.64645 13.6464L11.6464 7.64645C11.8417 7.45118 12.1583 7.45118 12.3536 7.64645L18.3536 13.6464C18.5488 13.8417 18.5488 14.1583 18.3536 14.3536Z"
          fill="#14141E"
        />
      </svg>
    </div>
    <!-- <GtBackToTop target="#nuxt-app" @click="handleScrollToTop">    
    </GtBackToTop> -->
    <!-- <Login :visible.sync="showLogin" :show-guest="showGuest" /> -->
    <!-- </template> -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { GtBackToTop } from '@speedshop/template';
import Login from '@/components/Login/drawerLogin.vue';
import { throttle } from '@/utils/tools';
import { getScrollTop, scrollToTopW } from '@/utils/dom';

export default {
  components: { Login, GtBackToTop },
  provide() {
    return {
      showLoginOrCustomService: this.showLoginOrCustomService
    };
  },
  data() {
    return {
      visibilityHeight: 400,
      showGuest: false,
      showLogin: false,
      timer: null,
      otherHiddenBackTop: true
    };
  },
  computed: {
    ...mapGetters(['isGuest', 'isLogin', 'isMobile']),

    scrollTop() {
      return this.$store.state.pageScrollY;
    },

    back2TopVisible() {
      return this.scrollTop >= this.visibilityHeight;
    }
  },
  mounted() {
    // this.lazyLoad();
    this.initLazyLoad();
    this.initScrollContainer();
    this.$eventBus.$on('hiddenBackTop', val => {
      this.otherHiddenBackTop = val;
    });
    /**
     * 滚动逻辑
     */
    this.lazyHandleScroll = throttle(this.handleScroll, 300);

    window.addEventListener('scroll', this.lazyHandleScroll, true);

    this.$on('hook:beforeDestroy', () => {
      window.removeEventListener('scroll', this.lazyHandleScroll, true);
    });
    this.initTrack();
  },
  methods: {
    initTrack() {
      function getDataEl(e) {
        if (e.tagName === 'A') {
          return e;
        } else if (e.parentElement) {
          return getDataEl(e.parentElement);
        } else {
          return false;
        }
      }
      document.body.addEventListener(
        'click',
        e => {
          const target = getDataEl(e.target);
          // console.log(target, 'target');
          if (target) {
            this.trackClick(target);
          }
        },
        false
      );
    },
    trackClick(target) {
      const attributes = target.attributes;
      const top = target.offsetTop;
      const viewHeight = window.innerHeight;
      console.log(target.offsetTop, 'target.offsetTop');

      const trackData = {};
      const previousData = {
        source_url: this.$route.path,
        page_source: this.$route.meta.title || this.$route.name
      };

      const path = (target.href || '/').replace(window.location.origin, '');
      let eventName;
      for (let i = 0; i < attributes.length; i++) {
        if (
          [
            'module_name',
            'module_rank',
            'operation_name',
            'operation_id',
            'operation_rank',
            'exposure_position',
            'exposure_sequence',
            'navigation_primary_category',
            'navigation_secondary_category',
            'navigation_third_category'
          ].includes(attributes[i].name)
        ) {
          previousData[attributes[i].name] = attributes[i].value;
          trackData[attributes[i].name] = attributes[i].value;
        }
        if (
          [
            'click_type',
            'product_rank',
            'primary_category',
            'secondary_category',
            'third_category',
            'expose_type',
            'gender',
            'spu_id',
            'spu_name',
            'sales_price',
            'list_price',
            'destination_url',
            'click_content',
            'button_name',
            'size',
            'color',
            'exposure_position',
            'exposure_sequence',
            'navigation_primary_category',
            'navigation_secondary_category',
            'navigation_third_category',
            'is_discount',
            'sku_name',
            'sku_id',
            'key_word',
            'key_word_type'
          ].includes(attributes[i].name) &&
          attributes[i].value
        ) {
          trackData[attributes[i].name] = attributes[i].value;
        }
        if (attributes[i].name === 'activity_attribute') {
          trackData[attributes[i].name] = JSON.parse(attributes[i].value);
        }
        if (attributes[i].name === 'is_discount') {
          trackData[attributes[i].name] = JSON.parse(attributes[i].value);
        }
        if (attributes[i].name === 'filter_content') {
          trackData[attributes[i].name] = JSON.parse(attributes[i].value);
        }

        if (attributes[i].name === 'event_name') {
          eventName = attributes[i].value;
        }
      }
      // LP 商品点击
      if (eventName === 'operationClick') {
        this.$trackHelp.track('operationClick', {
          ...trackData
        });
        // 购物车商品点击
      } else if (eventName === 'shoppingCartClick') {
        delete trackData.expose_type;
        delete trackData.click_type;
        delete trackData.destination_url;
        this.$trackHelp.track('shoppingCartClick', {
          ...trackData
        });
      } else if (eventName === 'operationDetailClick') {
        this.$trackHelp.track('operationDetailClick', {
          ...trackData
        });
      } else if (eventName === 'productListClick') {
        this.$trackHelp.track('productListClick', {
          ...trackData
        });
      } else if (eventName === 'reseachResultClick') {
        this.$trackHelp.track('reseachResultClick', {
          ...trackData
        });
      }
      if (eventName === 'UAStorClick') {
        this.$trackHelp.track('UAStorClick', {
          ...trackData
        });
      } else {
        this.$trackHelp.track('operationClick', {
          ...trackData
        });
      }

      this.$trackHelp.cachePageData(path, previousData);
    },
    initLazyLoad() {
      window.addEventListener('scroll', this.lazyLoad);
      window.addEventListener('resize', this.lazyLoad);
      window.addEventListener('orientationchange', this.lazyLoad);

      const targetNode = document.body;

      // 监听tab 切换
      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if ([...(mutation.target?.classList || [])].includes('el-tab-pane')) {
            if (this.timer) {
              clearTimeout(this.timer);
              this.timer = null;
            }
            // this.timer = setTimeout(async () => {
            //   await this.lazyLoad();
            //   this.timer = null;
            // }, 500);
          }
        });
      });

      const config = { childList: true, attributes: true, subtree: true };

      observer.observe(targetNode, config);
    },
    // lazyLoad() {
    //   const images = document.querySelectorAll('img[data-src]');
    //   images.forEach(image => {
    //     if (this.isInViewport(image)) {
    //       image.src = image.getAttribute('data-src');
    //       image.removeAttribute('data-src');
    //     }
    //   });
    // },
    // isInViewport(element) {
    //   const rect = element.getBoundingClientRect();

    //   return (
    //     rect.bottom > 0 &&
    //     rect.top < (window.innerHeight || document.documentElement?.clientHeight) + 200
    //   );
    // },
    initScrollContainer() {
      this.scrollElement = this.$refs.ScrollEle || document.getElementById('nuxt-app');
    },

    /**
     * 滚动监听
     */
    handleScroll() {
      this.$store.commit('UPDATE_PAGE_SCROLL_Y', getScrollTop());
    },

    /**
     * 回到顶部
     */
    handleScrollBack() {
      scrollToTopW();
    },

    /**
     * Global method to toggle the login component visible
     */
    showLoginOrCustomService(isShowGuest = true) {
      if (this.isLogin && !this.isGuest) {
        this.$customerService.open();
      } else {
        this.$eventBus.$emit('LoginShow', { showLogin: true, showGuest: isShowGuest });
      }
    },
    initListenClickLinkToTrack() {
      function findParentByClassName(element) {
        let currentElement = element;

        while (currentElement.parentElement) {
          if (currentElement.parentElement.tagName === 'A') {
            return currentElement.parentElement;
          }
          currentElement = currentElement.parentElement;
        }

        return null; // 没有找到目标父级元素
      }

      document.body.addEventListener(
        'click',
        e => {
          const aTag = findParentByClassName(e.target);
          console.log(aTag, 'atag');
          if (aTag) {
            const previousData = {
              source_url: this.$route.path,
              page_source: this.$route.meta.title || this.$route.name
            };
            const path = (aTag.href || '/').replace(window.location.origin, '');
            this.$trackHelp.cachePageData(path, previousData);
          }
        },
        false
      );
    }
  }
};
</script>

<style lang="scss">
// 解决 el 弹框sticky 失效
.el-popup-parent--hidden {
  padding-right: 0 !important;
  overflow: initial !important;
}
html {
  font-size: 14px;
  box-sizing: border-box;
  letter-spacing: 0;
  overflow-x: hidden;
  width: 100vw;
  body {
    width: 100vw;
  }
}

// 这条css的目的是，当页面出现抽屉组件时
// 1. 锁定文档的滚动行为
// 2. 隐藏文档的Y轴滚动条
html:has(.el-popup-parent--hidden) {
  overflow: hidden;
}

body {
  max-width: 100%;
  overflow: clip;
}
html,
#__nuxt,
#__layout,
#nuxt-app,
body {
  // height: 100%;
  font-family: var(--font-family-regular) !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  word-break: break-word;
}
#nuxt-app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  scroll-behavior: smooth;
  font-weight: 400;
  overflow-y: unset !important;
}
#page-view {
  flex-grow: 1;
  background-color: #fff;
  /* height: 100%; */
}
.page-layout_mobile {
  font-size: 12px;
}

.gt-backtop.el-backtop {
  background-color: transparent !important;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08) !important;
  backdrop-filter: blur(8px);
  z-index: 199;
  border-radius: 1px;
  background: var(--white-45, rgba(255, 255, 255, 0.45));

  flex-shrink: 0;

  &.hidden {
    display: none;
  }

  svg {
    width: 24px;
    height: 24px;
  }
}
.page-enter {
  opacity: 0;
}
.page-enter-active {
  opacity: 0;
}
.page-leave {
  opacity: 0;
}
.page-leave-active {
  opacity: 0;
}
</style>
